<template>
  <div v-if="!isLoading">
    <div class="container" @contextmenu="handlerRightClick($event)">
      <div class="wrapper">
        <b-loading v-model="isLoadingConnection"></b-loading>
        <!-- For header -->
        <div class="columns is-multiline is-centered">
          <!-- Header mobile -->
          <div class="is-hidden-desktop header-mobile column is-12">
            <span class="columns is-mobile header-mobile-column">
              <span class="column is-3 time-mobile">
                <b>{{ countdown }}</b>
              </span>
              <span class="column is-5 progress-mobile">
                <b-progress
                  type="is-hcc"
                  :value="progress"
                  show-value
                  format="percent"
                ></b-progress>
              </span>
              <span class="column is-4 btn-next-mobile">
                <b-field grouped position="is-right">
                  <span id="countdown" class="timer"></span>
                  <b-button
                    type="is-hcc"
                    @click="validationNextSection()"
                    v-if="progress != 100"
                  >
                    Next
                  </b-button>
                </b-field>
              </span>
            </span>
          </div>
        </div>
        <!-- Header desktop -->
        <div class="is-hidden-mobile header-desktop column is-12">
          <span class="columns header-desktop-column">
            <span class="column is-1">
              <b class="is-size-5">{{ countdown }}</b>
            </span>
            <span class="column is-6 bar">
              <b-field grouped position="is-right">
                <b-progress
                  type="is-hcc"
                  :value="progress"
                  show-value
                  format="percent"
                ></b-progress>
              </b-field>
            </span>
            <span class="column is-5 btn-next">
              <b-field grouped position="is-right">
                <span id="countdown" class="timer"></span>
                <b-button
                  type="is-hcc"
                  @click="validationNextSection()"
                  v-if="progress != 100"
                >
                  Next
                </b-button>
              </b-field>
            </span>
          </span>
        </div>

        <!-- For progress -->
        <span v-if="!isNotFound">
          <span class="columns is-multiline" v-if="!isCameraOff">
            <div class="contents column is-12" style="padding-bottom: 25px">
              <b-steps
                :has-navigation="false"
                v-model="activeStep"
                :size="size"
              >
                <!-- For take photo -->
                <b-step-item type="is-hcc">
                  <div class="column is-12">
                    <label class="label">
                      <b>
                        <b-field grouped position="is-centered">
                          <h1 class="is-size-3">
                            Silakan mengambil foto sebelum melanjutkan ke sesi
                            berikutnya
                          </h1>
                        </b-field>
                      </b>
                    </label>
                  </div>
                  <div class="column is-12">
                    <b-field grouped position="is-centered">
                      <div class="photo" v-if="file">
                        <img id="test" :src="file" />
                        <b-field grouped position="is-centered">
                          <b-button type="is-hcc" @click="modalTakePhoto()">
                            Take a photo
                          </b-button>
                        </b-field>
                      </div>
                      <div class="photo" v-else>
                        <img src="@/assets/image/user.png" />
                        <b-field
                          grouped
                          position="is-centered"
                          class="btn-take-photo"
                        >
                          <b-button
                            type="is-hcc"
                            size="is-medium"
                            @click="modalTakePhoto()"
                          >
                            Ambil Foto
                          </b-button>
                        </b-field>
                      </div>
                    </b-field>
                  </div>
                  <b-modal :active.sync="isTakePhotoModal" :width="400">
                    <div id="app" class="card web-camera-container">
                      <div class="camera-box" :class="{ flash: isShotPhoto }">
                        <div
                          class="camera-shutter"
                          :class="{ flash: isShotPhoto }"
                        ></div>

                        <video
                          v-show="!isPhotoTaken"
                          ref="camera"
                          :width="450"
                          :height="337.5"
                          autoplay
                        ></video>

                        <canvas
                          v-show="isPhotoTaken"
                          id="photoTaken"
                          ref="canvas"
                          :width="450"
                          :height="337.5"
                        ></canvas>
                      </div>

                      <div
                        v-if="isCameraOpen && !isLoadingCamera"
                        class="camera-shoot"
                      >
                        <button type="button" class="button" @click="takePhoto">
                          <img
                            src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
                          />
                        </button>
                      </div>
                    </div>
                  </b-modal>
                </b-step-item>

                <!-- For section -->
                <!-- <span v-for="(x, idx) in listSection" :key="'test' + idx">
                  TIME: {{ x.time }}
                </span> -->
                <b-step-item
                  v-for="(section, indexSection) in listSection"
                  :key="indexSection"
                >
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <br />
                      <span
                        :style="
                          `background-image: url(${getUrlFile(watermark)})`
                        "
                        v-if="section.isUseIntroduction"
                      >
                        <div class="box question">
                          <div v-html="checkName(section.introduction)"></div>
                        </div>
                      </span>

                      <ValidationObserver :ref="'formRegister-' + indexSection">
                        <form @submit.prevent="nextSection">
                          <template v-if="section.questionList.length > 0">
                            <span
                              v-for="(question,
                              indexQuestion) in section.questionList"
                              :key="indexQuestion"
                              :style="
                                `background-image: url(${getUrlFile(
                                  watermark
                                )})`
                              "
                            >
                              <div
                                class="box question"
                                :style="
                                  `background-image: url(${getUrlFile(
                                    watermark
                                  )})`
                                "
                                style="margin-top: 20px"
                                v-show="
                                  section.questionToDisplay *
                                    (currentPage - 1) <
                                    question.index &&
                                    section.questionToDisplay * currentPage >=
                                      question.index
                                "
                              >
                                <div
                                  class="columns is-multiline is-gapless"
                                  @copy="storeProctoringRecord('copy', $event)"
                                  @paste="
                                    storeProctoringRecord('paste', $event)
                                  "
                                  style="margin-bottom: 5px"
                                >
                                  <!-- For question -->
                                  <div class="column is-12">
                                    <b>Pertanyaan #{{ question.index }}</b>
                                    <span
                                      v-if="question.isRequired == true"
                                      class="required"
                                    >
                                      *
                                    </span>
                                  </div>
                                  <div class="column is-12">
                                    <!-- For question text -->
                                    <div v-html="question.question"></div>
                                    <br />

                                    <div v-if="question.files.length > 0">
                                      <div
                                        v-for="(file,
                                        indexFile) in question.files"
                                        :key="indexFile"
                                        class="question"
                                      >
                                        <div
                                          v-if="file.fileType == 'image'"
                                          style="margin-bottom: 20px"
                                        >
                                          <img
                                            :src="getUrlFile(file.filePath)"
                                            alt="Image"
                                          />
                                          <br />
                                        </div>
                                        <div v-else style="margin-bottom: 20px">
                                          <template
                                            v-if="file.maxPlayedVideo > 0"
                                          >
                                            <template
                                              v-if="
                                                file.maxPlayedVideo >
                                                  file.totalPlayedVideo
                                              "
                                            >
                                              <video-player
                                                class="video-player-box"
                                                width="320"
                                                height="240"
                                                controls="false"
                                                muted="false"
                                                autoplay
                                                @ended="
                                                  endVideo(
                                                    section.id,
                                                    question.id,
                                                    file.id,
                                                    indexSection,
                                                    indexQuestion,
                                                    indexFile
                                                  )
                                                "
                                                :ref="
                                                  'video_' +
                                                    question.index +
                                                    '_' +
                                                    indexSection +
                                                    '_' +
                                                    indexFile
                                                "
                                                :options="
                                                  setOptions(
                                                    getUrlFile(file.filePath)
                                                  )
                                                "
                                              ></video-player>
                                              <b-button
                                                type="is-hcc"
                                                @click="
                                                  playVideo(
                                                    question.index,
                                                    indexSection,
                                                    indexFile
                                                  )
                                                "
                                                style="margin-top: 10px"
                                              >
                                                Play
                                              </b-button>
                                            </template>
                                          </template>
                                          <template v-else>
                                            <video-player
                                              class="video-player-box"
                                              width="320"
                                              height="240"
                                              controls="false"
                                              muted="false"
                                              autoplay
                                              @ended="
                                                endVideo(
                                                  section.id,
                                                  question.id,
                                                  file.id,
                                                  indexSection,
                                                  indexQuestion,
                                                  indexFile
                                                )
                                              "
                                              :ref="
                                                'video_' +
                                                  question.index +
                                                  '_' +
                                                  indexSection +
                                                  '_' +
                                                  indexFile
                                              "
                                              :options="
                                                setOptions(
                                                  getUrlFile(file.filePath)
                                                )
                                              "
                                            ></video-player>
                                            <b-button
                                              type="is-hcc"
                                              @click="
                                                playVideo(
                                                  question.index,
                                                  indexSection,
                                                  indexFile
                                                )
                                              "
                                              style="margin-top: 10px"
                                            >
                                              Play
                                            </b-button>
                                          </template>
                                          <br />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <!-- For answer -->
                                  <template
                                    v-if="question.questionType == 'essay'"
                                  >
                                    <template>
                                      <div
                                        class="column is-12"
                                        v-if="
                                          !question.isExample ||
                                            (question.hasAnswer &&
                                              question.isExample)
                                        "
                                        style="margin-bottom: 5px"
                                      >
                                        <b>Jawaban</b>
                                      </div>
                                      <div class="column is-12">
                                        <!-- For type essay -->
                                        <answer-type-essay
                                          :data="question"
                                          :indexSection="indexSection"
                                          :indexQuestion="indexQuestion"
                                          :sectionId="section.id"
                                          @clicked="autoSave($event)"
                                          @copy-text="
                                            storeProctoringRecord(
                                              'copy',
                                              $event
                                            )
                                          "
                                          @paste-text="
                                            storeProctoringRecord(
                                              'paste',
                                              $event
                                            )
                                          "
                                        />
                                      </div>
                                    </template>
                                  </template>
                                  <template v-else>
                                    <div
                                      class="column is-12"
                                      style="margin-bottom: 5px"
                                    >
                                      <b>Jawaban</b>
                                    </div>
                                    <div class="column is-12">
                                      <!-- For type radio button -->
                                      <template
                                        v-if="
                                          question.questionType ==
                                            'radio button'
                                        "
                                      >
                                        <answer-type-radio-button
                                          :data="question"
                                          :indexSection="indexSection"
                                          :indexQuestion="indexQuestion"
                                          :sectionId="section.id"
                                          @clicked="autoSave($event)"
                                          @copy-text="
                                            storeProctoringRecord(
                                              'copy',
                                              $event
                                            )
                                          "
                                        />
                                      </template>

                                      <!-- For type checkbox -->
                                      <template
                                        v-else-if="
                                          question.questionType == 'checkbox'
                                        "
                                      >
                                        <answer-type-checkbox
                                          :data="question"
                                          :indexSection="indexSection"
                                          :indexQuestion="indexQuestion"
                                          :sectionId="section.id"
                                          @clicked="autoSave($event)"
                                          @copy-text="
                                            storeProctoringRecord(
                                              'copy',
                                              $event
                                            )
                                          "
                                        />
                                      </template>

                                      <!-- For type linear scale -->
                                      <template
                                        v-else-if="
                                          question.questionType ==
                                            'linear scale'
                                        "
                                      >
                                        <answer-type-linear-scale
                                          :data="question"
                                          :indexSection="indexSection"
                                          :indexQuestion="indexQuestion"
                                          :sectionId="section.id"
                                          @clicked="autoSave($event)"
                                          @copy-text="
                                            storeProctoringRecord(
                                              'copy',
                                              $event
                                            )
                                          "
                                        />
                                      </template>

                                      <!-- For type star rating -->
                                      <template
                                        v-else-if="
                                          question.questionType == 'star rating'
                                        "
                                      >
                                        <answer-type-star-rating
                                          :data="question"
                                          :indexSection="indexSection"
                                          :indexQuestion="indexQuestion"
                                          :sectionId="section.id"
                                          @clicked="autoSave($event)"
                                          @copy-text="
                                            storeProctoringRecord(
                                              'copy',
                                              $event
                                            )
                                          "
                                        />
                                      </template>

                                      <!-- For type ranking -->
                                      <template
                                        v-else-if="
                                          question.questionType == 'ranking'
                                        "
                                      >
                                        <answer-type-ranking
                                          :data="question"
                                          :indexSection="indexSection"
                                          :indexQuestion="indexQuestion"
                                          :sectionId="section.id"
                                          @clicked="autoSave($event)"
                                          @copy-text="
                                            storeProctoringRecord(
                                              'copy',
                                              $event
                                            )
                                          "
                                        />
                                      </template>

                                      <!-- For type matrix -->
                                      <template
                                        v-else-if="
                                          question.questionType == 'matrix'
                                        "
                                      >
                                        <answer-type-matrix
                                          :data="question"
                                          :indexSection="indexSection"
                                          :indexQuestion="indexQuestion"
                                          :sectionId="section.id"
                                          @clicked="autoSave($event)"
                                          @copy-text="
                                            storeProctoringRecord(
                                              'copy',
                                              $event
                                            )
                                          "
                                          @paste-text="
                                            storeProctoringRecord(
                                              'paste',
                                              $event
                                            )
                                          "
                                          v-if="
                                            question.questionType == 'matrix'
                                          "
                                        />
                                      </template>
                                    </div>
                                  </template>
                                </div>
                              </div>
                            </span>
                          </template>
                        </form>
                      </ValidationObserver>

                      <span class="column is-12">
                        <b-pagination
                          :current.sync="currentPage"
                          :total="section.counter"
                          :per-page="section.questionToDisplay"
                          aria-next-label="Next page"
                          aria-previous-label="Previous page"
                          aria-page-label="Page"
                          aria-current-label="Current page"
                        ></b-pagination>
                      </span>
                    </div>
                  </div>
                </b-step-item>

                <!-- For thank you page -->
                <b-step-item class="step-thank-you">
                  <h3>{{ thankYouPage.title }}</h3>
                  <div v-html="thankYouPage.description"></div>
                  <br />
                  <br />
                  <br />
                  Anda akan pindah page otomatis dalam waktu
                  {{ countdownRedirect }} seconds
                </b-step-item>
              </b-steps>
            </div>
          </span>
          <span v-else>
            <CameraNotFound />
          </span>
        </span>
        <span v-else>
          <NotFound />
        </span>

        <video
          hidden
          v-show="!isPhotoTaken"
          ref="cameraHidden"
          :width="450"
          :height="337.5"
          autoplay
        ></video>
        <canvas
          hidden
          id="hiddenCanvas"
          ref="canvasHidden"
          :width="450"
          :height="337.5"
        ></canvas>

        <!-- Video offscreen -->
        <video
          id="video"
          style="position: absolute; top: -10000px; left: -10000px"
          autoplay
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
import NotFound from '@/components/NotFound.vue'
import CameraNotFound from '@/components/CameraNotFound.vue'
import { mapActions } from 'vuex'

import AnswerTypeEssay from '@/components/V2/Candidat/AnswerTypeEssay.vue'
import AnswerTypeRadioButton from '@/components/V2/Candidat/AnswerTypeRadioButton.vue'
import AnswerTypeLinearScale from '@/components/V2/Candidat/AnswerTypeLinearScale.vue'
import AnswerTypeCheckbox from '@/components/V2/Candidat/AnswerTypeCheckbox.vue'
import AnswerTypeRanking from '@/components/V2/Candidat/AnswerTypeRanking.vue'
import AnswerTypeStarRating from '@/components/V2/Candidat/AnswerTypeStarRating.vue'
import AnswerTypeMatrix from '@/components/V2/Candidat/AnswerTypeMatrix.vue'
import { videoPlayer } from 'vue-video-player'

export default {
  name: 'answer-bank-soal',
  components: {
    NotFound,
    CameraNotFound,
    videoPlayer,
    AnswerTypeEssay,
    AnswerTypeRadioButton,
    AnswerTypeLinearScale,
    AnswerTypeCheckbox,
    AnswerTypeRanking,
    AnswerTypeStarRating,
    AnswerTypeMatrix,
  },
  data() {
    return {
      isNotFound: false,
      isCameraOff: false,
      isCameraOpen: false,
      isLoading: true,
      isLoadingCamera: false,
      isTakePhotoModal: false,
      isPhotoTaken: false,
      isShotPhoto: false,

      progress: 0,
      section: 0,
      countdown: '',
      size: 'small',
      file: null,
      interval: null,
      ssInterval: null,

      watermark: '',
      thankYouPage: {
        title: '',
        description: '',
      },
      activeStep: 0,
      screencaptureTime: 0,
      screenshotTime: 0,
      currentPage: 1,
      listSection: [],

      openTab: 0,
      dialogOpenTab: false,
      arrayConnectionFailed: [],
      dialogConnectionFailed: false,
      isLoadingConnection: false,

      countdownRedirect: 10,
    }
  },
  beforeDestroy() {
    // document.removeEventListener('visibilitychange', this.showAlert)
    window.removeEventListener('blur', this.storeOpenNewTab)
  },
  created() {
    this.decreaseCountdownRedirect()
  },
  mounted() {
    // document.addEventListener('visibilitychange', this.showAlert)
    window.addEventListener('blur', this.storeOpenNewTab)

    this.fetchData()
    document.addEventListener('keyup', function(e) {
      var keyCode = e.keyCode ? e.keyCode : e.which
      if (keyCode == 44) {
        stopPrntScr()
      } else if (keyCode == 18) {
        stopPrntScr()
      }
    })
    function stopPrntScr() {
      var inpFld = document.createElement('input')
      inpFld.setAttribute('value', '.')
      inpFld.setAttribute('width', '0')
      inpFld.style.height = '0px'
      inpFld.style.width = '0px'
      inpFld.style.border = '0px'
      document.body.appendChild(inpFld)
      inpFld.select()
      document.execCommand('copy')
      inpFld.remove(inpFld)
    }
  },
  watch: {
    activeStep: function(val) {
      if (val == 1) {
        if (
          !this.$route.query.preview ||
          (this.$route.query.preview && this.$route.query.preview != 'preview')
        ) {
          this.sendCandidatPhoto()
        }
        this.isCameraOpen = true
        this.createHiddenCameraElement()
        this.interval = setInterval(
          () => this.takeScreenShot(),
          this.screenshotTime * 1000
        )

        if (val == 1 + this.listSection.length) {
          this.isCountDown = false
          this.seconds = 0
          this.countdown = ''
          // document.getElementById('countDownTimer').innerHTML = this.countdown
          // document.getElementById('countDownTimer2').innerHTML = this.countdown
          this.progress = 100
        } else {
          if (this.getSectionType() == 'questionTest') {
            if (this.listSection[this.section].isUseTime) {
              if (this.isUpdatedContent) {
                this.seconds = this.updateSeconds
              } else {
                if (
                  localStorage.getItem(
                    'time' +
                      this.$route.query.nit +
                      this.$route.params.assessmentCode
                  ) != null
                ) {
                  this.seconds = localStorage.getItem(
                    'time' +
                      this.$route.query.nit +
                      this.$route.params.assessmentCode
                  )
                } else {
                  this.seconds = this.listSection[this.section].time
                }
              }
              this.isCountDown = true
              this.timerInterval = setInterval(() => this.timer(), 1000)
            } else {
              this.isCountDown = false
              this.seconds = 0
              this.countdown = ''
              // document.getElementById(
              //   'countDownTimer'
              // ).innerHTML = this.countdown
              // document.getElementById(
              //   'countDownTimer2'
              // ).innerHTML = this.countdown
            }
          } else {
            this.isCountDown = false
            this.seconds = 0
            this.countdown = ''
            // document.getElementById('countDownTimer').innerHTML = this.countdown
            // document.getElementById(
            //   'countDownTimer2'
            // ).innerHTML = this.countdown
          }
        }
      }
      if (val >= 2) {
        if (
          this.section != this.listSection.length &&
          this.section != this.activeStep - 1
        ) {
          this.section += 1
        }
        if (this.isCountDown == false) {
          clearInterval(this.timerInterval)
        }
        if (val == 1 + this.listSection.length) {
          this.isCountDown = false
          this.seconds = 0
          this.countdown = ''
          // document.getElementById('countDownTimer').innerHTML = this.countdown
          // document.getElementById('countDownTimer2').innerHTML = this.countdown
          this.progress = 100
        } else {
          if (this.getSectionType() == 'questionTest') {
            if (this.listSection[this.section].isUseTime) {
              if (this.isUpdatedContent) {
                this.seconds = this.updateSeconds
              } else {
                if (
                  localStorage.getItem(
                    'time' +
                      this.$route.query.nit +
                      this.$route.params.assessmentCode
                  ) != null
                ) {
                  this.seconds = localStorage.getItem(
                    'time' +
                      this.$route.query.nit +
                      this.$route.params.assessmentCode
                  )
                } else {
                  this.seconds = this.listSection[this.section].time
                }
              }
              this.isCountDown = true
              this.timerInterval = setInterval(() => this.timer(), 1000)
            } else {
              this.isCountDown = false
              this.seconds = 0
              this.countdown = ''
              // document.getElementById(
              //   'countDownTimer'
              // ).innerHTML = this.countdown
              // document.getElementById(
              //   'countDownTimer2'
              // ).innerHTML = this.countdown
            }
          } else {
            this.isCountDown = false
            this.seconds = 0
            this.countdown = ''
            // document.getElementById('countDownTimer').innerHTML = this.countdown
            // document.getElementById(
            //   'countDownTimer2'
            // ).innerHTML = this.countdown
          }
        }

        if (
          localStorage.getItem(
            'time' + this.$route.query.nit + this.$route.params.assessmentCode
          ) != undefined
        ) {
          this.seconds = localStorage.getItem(
            'time' + this.$route.query.nit + this.$route.params.assessmentCode
          )
        }
      }

      if (val >= 1) {
        if (
          !this.$route.query.preview ||
          (this.$route.query.preview && this.$route.query.preview != 'preview')
        ) {
          this.autoSaveActiveStep()
        }
      }

      this.currentPage = 1
      this.progress = (val / (1 + this.listSection.length)) * 100

      if (this.progress >= 100) {
        this.$store.dispatch('sendToCapture', {
          nit: this.$route.query.nit,
          nama: this.$route.query.nama,
          email: this.$route.query.email,
          cid: this.$route.query.cid,
          uid: this.$route.query.uid,
          assessment_code: this.$route.params.assessmentCode,
          id_tes: this.$route.query.id_tes,
          app_url: this.$route.query.app_url,
        })
      }
    },
  },
  methods: {
    // For map actions
    ...mapActions({
      fetchAnswers: 'newAnswer/fetchAnswers',
      updateAnswer: 'newAnswer/updateAnswer',
      updateAnswerConnectionFailed: 'newAnswer/updateAnswerConnectionFailed',
      updateActiveStep: 'newAnswer/updateActiveStep',
      updateTime: 'newAnswer/updateTime',
      candidatPhoto: 'newAnswer/candidatPhoto',
      storeHiddenPhoto: 'newAnswer/storeHiddenPhoto',
      storeVideoPlayed: 'newAnswer/storeVideoPlayed',
      storeHiddenScreenCapture: 'newAnswer/storeHiddenScreenCapture',
    }),

    getSectionType() {
      return this.listSection[this.section] ? this.listSection[this.section].type : '';
    },

    // For countdown redirect
    decreaseCountdownRedirect() {
      if (this.countdownRedirect > 0) {
        setTimeout(() => {
          if (this.listSection.length + 1 == this.activeStep) {
            this.countdownRedirect--
            if (this.countdownRedirect <= 0) {
              location.replace(
                'https://' + this.$route.query.app_url + 'psikotest'
              )
            }
          }
          this.decreaseCountdownRedirect()
        }, 1000)
      }
    },

    // For check name
    checkName(text) {
      let textData = text.replace('#nama#', this.$route.query.nama)

      return textData
    },

    // For play video
    playVideo(index, section, indexFile) {
      this.$refs[
        'video_' + index + '_' + section + '_' + indexFile
      ][0].player.play()
    },

    // For end video
    async endVideo(
      newSectionId,
      newQuestionId,
      newFileId,
      indexSection,
      indexQuestion,
      indexFile
    ) {
      if (
        !this.$route.query.preview ||
        (this.$route.query.preview && this.$route.query.preview != 'preview')
      ) {
        let data = {
          nit: this.$route.query.nit,
          code: this.$route.params.assessmentCode,
          tesId: this.$route.query.id_tes,
          newSectionId: newSectionId,
          newQuestionId: newQuestionId,
          newFileId: newFileId,
        }
        const response = await this.storeVideoPlayed(data)
        this.listSection[indexSection].questionList[indexQuestion].files[
          indexFile
        ].totalPlayedVideo = response.data.videoPlayed
      } else {
        this.listSection[indexSection].questionList[indexQuestion].files[
          indexFile
        ].totalPlayedVideo += 1
      }
    },

    // For set options
    setOptions(src) {
      return {
        // videojs options
        muted: false,
        controls: false,
        width: 320,
        height: 240,
        language: 'en',
        sources: [
          {
            type: 'video/mp4',
            src: src,
          },
        ],
      }
    },

    // For background url file link
    getUrlFile(file) {
      return process.env.VUE_APP_BACKEND_URL + file
    },

    // For right click disabled
    handlerRightClick(event) {
      event.preventDefault()
    },

    // For validation next section
    validationNextSection() {
      if (this.section < 0) {
        this.section = 0
      }
      if (this.getSectionType() == 'questionTest') {
        this.popUpMoveToNextSection()
      } else {
        this.nextSection()
      }
    },

    // For popup move to next section
    popUpMoveToNextSection() {
      this.$buefy.dialog.confirm({
        title: 'Move To other section',
        message: `Anda akan berpindah ke halaman berikutnya. Apakah Anda sudah yakin dengan seluruh jawaban Anda?`,
        cancelText: 'Belum, saya ingin mengeceknya kembali.',
        confirmText: 'Sudah, saya ingin berpindah ke halaman berikutnya',
        type: 'is-success',
        onConfirm: () => this.nextSection(),
      })
    },

    // For fetch data
    async fetchData() {
      this.isLoading = true
      let preview = 'preview'
      if (
        !this.$route.query.preview ||
        (this.$route.query.preview && this.$route.query.preview != 'preview')
      ) {
        preview = 'not preview'
      }
      let data = {
        nit: this.$route.query.nit,
        code: this.$route.params.assessmentCode,
        name: this.$route.query.nama,
        appUrl: this.$route.query.app_url,
        email: this.$route.query.email,
        cid: this.$route.query.cid,
        uid: this.$route.query.uid,
        tesId: this.$route.query.id_tes,
        preview: preview,
      }
      const response = await this.fetchAnswers(data)

      this.watermark = response.data.watermark
      this.screencaptureTime = response.data.screencaptureTime
      this.screenshotTime = response.data.screenshotTime
      this.listSection = response.data.sectionList
      this.activeStep = response.data.activeStep
      this.section = response.data.activeStep - 1
      this.thankYouPage.title = response.data.thankYouPageTitle
      this.thankYouPage.description = response.data.thankYouPageDescription
      this.openTab = response.data.openTab

      if (this.activeStep >= 1 && preview == 'not preview') {
        if (this.getSectionType() == 'questionTest') {
          if (this.listSection[this.section].isUseTime == true) {
            this.seconds = localStorage.getItem(
              'time' + this.$route.query.nit + this.$route.params.assessmentCode
            )
            this.autoSaveTime(this.listSection[this.section].id)
          }
        }
      }

      this.progress = (this.activeStep / (1 + this.listSection.length)) * 100

      this.startRecording()
      if (this.activeStep > 1) {
        this.isCameraOpen = true
        this.createHiddenCameraElement()
        this.interval = setInterval(
          () => this.takeScreenShot(),
          this.screenshotTime * 1000
        )
      }

      this.isLoading = false
    },

    // For modal take photo
    modalTakePhoto() {
      this.isCameraOpen = true
      this.createCameraElement()
      this.isTakePhotoModal = true
    },

    // For toogle camera
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false
        this.isPhotoTaken = false
        this.isShotPhoto = false
        this.stopCameraStream()
      } else {
        this.isCameraOpen = true
        this.createCameraElement()
      }
    },

    // For create element camera (photo candidat)
    createCameraElement() {
      this.isLoadingCamera = true

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      })

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoadingCamera = false
          this.$refs.camera.srcObject = stream
        })
        .catch(() => {
          this.isLoadingCamera = false
          this.isCameraOff = true
          alert('Kamera tidak ditemukan')
        })
    },

    // For store hidden camera
    createHiddenCameraElement() {
      this.isLoadingCamera = true

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      })

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoadingCamera = false
          this.$refs.cameraHidden.srcObject = stream
        })
        .catch(() => {
          this.isLoadingCamera = false
          this.isCameraOff = true
          alert('Kamera tidak ditemukan')
        })
    },

    // For stop camera stream
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks()

      tracks.forEach(track => {
        track.stop()
      })
    },

    // For send first photo of candidat
    async sendCandidatPhoto() {
      let formData = new FormData()
      formData.append('image', this.file)
      formData.append('nit', this.$route.query.nit)
      formData.append('assessmentCode', this.$route.params.assessmentCode)
      await this.candidatPhoto(formData)
    },

    // For timer
    timer() {
      var hoursLeft = Math.floor(this.seconds - 0 * 86400)
      var hours = Math.floor(hoursLeft / 3600)
      var minutesLeft = Math.floor(hoursLeft - hours * 3600)
      var minutes = Math.floor(minutesLeft / 60)
      var remainingSeconds = this.seconds % 60
      this.countdown =
        this.pad(hours) +
        ':' +
        this.pad(minutes) +
        ':' +
        this.pad(remainingSeconds)
      // document.getElementById('countDownTimer').innerHTML = this.countdown
      // document.getElementById('countDownTimer2').innerHTML = this.countdown
      localStorage.setItem(
        'time' + this.$route.query.nit + this.$route.params.assessmentCode,
        this.seconds
      )

      if (this.seconds != 0) {
        this.seconds = this.seconds - 1
      } else {
        this.isCountDown = false

        this.seconds = 0
        this.countdown = ''
        // document.getElementById('countDownTimer').innerHTML = this.countdown
        // document.getElementById('countDownTimer2').innerHTML = this.countdown
        localStorage.removeItem(
          'time' + this.$route.query.nit + this.$route.params.assessmentCode
        )
        this.autoSaveTime(this.listSection[this.section].id)
        this.activeStep += 1
      }
    },

    // For pad
    pad(n) {
      return n < 10 ? '0' + n : n
    },

    // For auto save
    async autoSave(data) {
      // content.id_tes = this.$route.query.id_tes
      // content.assessmentId = this.$route.params.assessmentId
      // content.answer = this.listSection
      // content.time = this.seconds
      // content.activeStep = this.activeStep
      // content.uid = this.$route.query.uid
      // content.cid = this.$route.query.cid
      // content.email = this.$route.query.email
      // content.name = this.$route.query.nama
      // let finish = false
      // if (this.progress == 100) {
      //   finish = true
      // }

      if (
        !this.$route.query.preview ||
        (this.$route.query.preview && this.$route.query.preview != 'preview')
      ) {
        if (this.activeStep >= 1) {
          let form = {
            nit: this.$route.query.nit,
            code: this.$route.params.assessmentCode,
            tesId: this.$route.query.id_tes,
            newSectionId: data.newSectionId,
            newQuestionId: data.newQuestionId,
            newAnswerId: data.newAnswerId,
            description: data.description,
            rating: data.rating,
            indexQuestion: data.indexQuestion,
            indexAnswer: data.indexAnswer,
            column: data.column,
            row: data.row,
            time: this.seconds,
            // isFinish: finish,
            activeStep: this.activeStep,
          }
          try {
            await this.updateAnswer(form)
          } catch (err) {
            // this.danger(err)
            this.closeConnectionFailedDialog(form)
          }
        }
      }
    },

    closeConnectionFailedDialog(form) {
      this.isLoadingConnection = false
      if (form !== 'reload') {
        if (this.arrayConnectionFailed.length) {
          for (var i in this.arrayConnectionFailed) {
            if (
              this.arrayConnectionFailed[i]['newQuestionId'] ==
                form['newQuestionId'] &&
              form['row'] &&
              form['column']
            ) {
              if (
                form['row'] == this.arrayConnectionFailed[i]['row'] &&
                form['column'] == this.arrayConnectionFailed[i]['column']
              ) {
                this.$delete(this.arrayConnectionFailed, i)
                this.arrayConnectionFailed.push(form)
              } else {
                this.arrayConnectionFailed.push(form)
              }
            } else if (
              this.arrayConnectionFailed[i]['newQuestionId'] ==
              form['newQuestionId']
            ) {
              this.$delete(this.arrayConnectionFailed, i)

              this.arrayConnectionFailed.push(form)
            } else {
              this.arrayConnectionFailed.push(form)
            }
          }
        } else {
          this.arrayConnectionFailed.push(form)
        }
      }

      if (this.dialogConnectionFailed == false) {
        this.dialogConnectionFailed = true
        this.$buefy.dialog.alert({
          title: 'Connection Failed',
          confirmText: 'Reconnect',
          message: 'Internet kamu terputus, Silahkan tekan tombol Reconnect',
          type: 'is-danger',
          ariaModal: true,
          closeOnConfirm: true,
          canCancel: false,
          onConfirm: () =>
            this.saveConnectionFailed(this.arrayConnectionFailed),
        })
      }
    },

    // For save connection failed
    async saveConnectionFailed(form) {
      if (
        !this.$route.query.preview ||
        (this.$route.query.preview && this.$route.query.preview != 'preview')
      ) {
        this.dialogOpenTab = false
        this.isLoadingConnection = true
        this.dialogConnectionFailed = false

        if (this.activeStep >= 1) {
          try {
            await this.updateAnswerConnectionFailed(form)
            this.arrayConnectionFailed = []
            this.isLoadingConnection = false
            this.$buefy.toast.open({
              message: 'Answer saved successfully',
              type: 'is-success',
            })
          } catch (err) {
            this.closeConnectionFailedDialog('reload')
          }
        }
      }
    },

    async autoSaveActiveStep() {
      let finish = false
      if (this.progress == 100) {
        finish = true
      }
      let form = {
        nit: this.$route.query.nit,
        code: this.$route.params.assessmentCode,
        tesId: this.$route.query.id_tes,
        activeStep: this.activeStep,
        isFinish: finish,
      }

      try {
        await this.updateActiveStep(form)
      } catch (err) {
        this.danger(err)
      }
    },

    async autoSaveTime(sectionId) {
      let form = {
        nit: this.$route.query.nit,
        code: this.$route.params.assessmentCode,
        tesId: this.$route.query.id_tes,
        sectionId: sectionId,
        time: this.seconds,
      }

      await this.updateTime(form)
    },

    // For store proctoring record
    async storeProctoringRecord(action, event) {
      if (
        !this.$route.query.preview ||
        (this.$route.query.preview && this.$route.query.preview != 'preview')
      ) {
        if (action == 'copy') {
          const selection = document.getSelection()

          let content = {
            nit: this.$route.query.nit,
            code: this.$route.params.assessmentCode,
            tesId: this.$route.query.id_tes,
            action: action,
            text: selection.toString(),
            target: null,
          }
          await this.$store.dispatch('newAnswer/storeProctoringRecord', content)
        } else {
          let content = {
            nit: this.$route.query.nit,
            code: this.$route.params.assessmentCode,
            tesId: this.$route.query.id_tes,
            action: action,
            text: event.clipboardData.getData('text'),
            target: event.target,
          }
          await this.$store.dispatch('newAnswer/storeProctoringRecord', content)
        }
      }
    },
    // showAlert() {
    //   if (this.activeStep > 0) {
    //     if (!document.hidden) {
    //       //when focus
    //       alert(
    //         'Kamu sudah meninggalkan tab ini sebanyak ' +
    //           this.openTab +
    //           ' kali, hal ini akan mempengaruhi nilaimu'
    //       )
    //     }
    //   }
    // },
    // For store open new tab
    async storeOpenNewTab() {
      if (this.activeStep > 0) {
        if (this.dialogOpenTab == false) {
          if (this.openTab == null) {
            this.openTab = 0
          }
          this.dialogOpenTab = true
          this.openTab++
          let form = {
            nit: this.$route.query.nit,
            code: this.$route.params.assessmentCode,
            tesId: this.$route.query.id_tes,
            openTab: this.openTab,
          }
          if (
            !this.$route.query.preview ||
            (this.$route.query.preview &&
              this.$route.query.preview != 'preview')
          ) {
            const res = await this.$store.dispatch(
              'newAnswer/storeOpenNewTab',
              form
            )
            if (res.data == 'Add Tab Success') {
              this.$buefy.dialog.alert({
                title: 'Warning',
                message:
                  ' PERINGATAN Anda telah meninggalkan tab halaman Psikotes sebanyak ' +
                  this.openTab +
                  ' kali',
                type: 'is-warning',
                ariaModal: true,
                onConfirm: () => (this.dialogOpenTab = false),
              })
            }
          } else {
            //preview
            this.$buefy.dialog.alert({
              title: 'Warning',
              message:
                ' PERINGATAN Anda telah meninggalkan tab halaman Psikotes sebanyak ' +
                this.openTab +
                ' kali',
              type: 'is-warning',
              ariaModal: true,
              onConfirm: () => (this.dialogOpenTab = false),
            })
          }
        }
      }
    },

    // For start screen shot
    async startScreenShot() {
      this.interval = await setInterval(
        () => this.takeScreenShot(),
        this.screenshotTime * 1000
      )
    },

    // For take photo
    async takePhoto() {
      this.file = null
      this.isProcessPhoto = false
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true

        const FLASH_TIMEOUT = 50

        setTimeout(() => {
          this.isShotPhoto = false
        }, FLASH_TIMEOUT)
      }

      const context = this.$refs.canvas.getContext('2d')
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5)
      this.file = document.getElementById('photoTaken').toDataURL('image/jpeg')

      this.isProcessPhoto = true
      this.isTakePhotoModal = false
      this.stopCameraStream()
    },

    // For take screen shot
    takeScreenShot() {
      /*  
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true
        const FLASH_TIMEOUT = 50
        setTimeout(() => {
          this.isShotPhoto = false
        }, FLASH_TIMEOUT)
      }
      */
      if (
        !this.$route.query.preview ||
        (this.$route.query.preview && this.$route.query.preview != 'preview')
      ) {
        const context = this.$refs.canvasHidden.getContext('2d')
        context.drawImage(this.$refs.cameraHidden, 0, 0, 450, 337.5)

        var image = document.getElementById('hiddenCanvas')
        let formData = new FormData()
        formData.append('image', image.toDataURL())
        formData.append('nit', this.$route.query.nit)
        formData.append('assessmentCode', this.$route.params.assessmentCode)
        this.storeHiddenPhoto(formData)
      }
    },

    // For start recording
    async startRecording() {
      const displayMediaOptions = {
        video: {
          mediaSource: 'screen',
          cursor: 'always',
        },
        videoBitsPerSecond: 1,
        audio: false,
      }

      const stream = await navigator.mediaDevices.getDisplayMedia(
        displayMediaOptions
      )
      stream.getVideoTracks()[0].addEventListener('ended', () => {
        clearInterval(this.ssInterval)
      })
      stream.onended = function() {
        clearInterval(this.ssInterval)
      }

      const video = document.getElementById('video')
      video.onloadedmetadata = () => {
        video.play()
      }

      video.srcObject = stream
      this.captureSS()
    },

    // for capture screen capture
    async captureSS() {
      this.ssInterval = setInterval(() => {
        var canvas = document.createElement('canvas')
        canvas.width = 1280
        canvas.height = 720
        var ctx = canvas.getContext('2d')

        // Draw video on canvas
        const video = document.getElementById('video')
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

        const img = canvas.toDataURL('image/jpeg')
        this.storeHidenScreenShot(img)
      }, this.screencaptureTime * 1000)
    },

    // For store hidden screen capture
    async storeHidenScreenShot(img) {
      let formData = new FormData()
      formData.append('image', img)
      formData.append('nit', this.$route.query.nit)
      formData.append('assessmentCode', this.$route.params.assessmentCode)

      if (
        !this.$route.query.preview ||
        (this.$route.query.preview && this.$route.query.preview != 'preview')
      ) {
        await this.storeHiddenScreenCapture(formData)
      }
    },

    // For next section
    nextSection() {
      if (
        localStorage.getItem(
          'time' + this.$route.query.nit + this.$route.params.assessmentCode
        )
      ) {
        localStorage.removeItem(
          'time' + this.$route.query.nit + this.$route.params.assessmentCode
        )
      }

      document.documentElement.scrollTop = 0

      if (this.activeStep == 0) {
        if (this.file != null) {
          this.activeStep += 1
        } else {
          this.danger(
            'Silahkan mengambil foto sebelum melanjutkan ke sesi berikutnya'
          )
        }
      } else {
        if (
          this.getSectionType() == 'questionTest' ||
          this.getSectionType() == 'introductionTest'
        ) {
          this.$refs['formRegister-' + this.section][0]
            .validate()
            .then(success => {
              if (!success) {
                this.danger(
                  'Ada soal yang Anda lewati. Kerjakan dulu soal tersebut untuk dapat melanjutkan proses ke halaman berikutnya.'
                )
              } else {
                if (this.activeStep == 0) {
                  if (this.file != null) {
                    this.activeStep += 1
                  } else {
                    this.danger(
                      'Silahkan mengambil foto sebelum melanjutkan ke sesi berikutnya'
                    )
                  }
                } else {
                  if (this.activeStep != this.listSection.length + 2) {
                    this.activeStep += 1
                    // this.section += 1;
                    this.seconds = 0
                    this.isCountDown = false
                  }
                }
              }
            })
        } else {
          if (this.activeStep == 0) {
            if (this.file != null) {
              this.activeStep += 1
            } else {
              this.danger(
                'Silahkan mengambil foto sebelum melanjutkan ke sesi berikutnya'
              )
            }
          } else {
            if (this.activeStep != this.listSection.length + 2) {
              this.activeStep += 1
            }
          }
        }
      }
    },

    // For success alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // FOr danger alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
        duration: 5000,
      })
    },
  },
}
</script>

<style lang="scss">
.b-steps .steps {
  display: none !important;
}
.jump-button {
  width: 3%;
  margin-top: 1%;
  margin-right: 1%;
}
.header {
  &-mobile {
    overflow: hidden;
    background-color: whitesmoke;
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    margin-left: 1%;
    padding-top: 1%;
    height: 10%;

    &-column {
      margin-left: 0.25rem !important;
    }
  }

  &-desktop {
    overflow: hidden;
    background-color: whitesmoke;
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    margin-left: 0;
    padding-top: 1%;

    &-column {
      margin-left: 0.25rem !important;
    }
  }
}
.btn-next-mobile {
  padding-top: 6.5%;
  padding-right: 3%;
}
.progress-mobile {
  margin-top: 6%;
}
.time-mobile {
  margin-top: 5%;
}
.pagination-footer {
  overflow: hidden;
  position: fixed;
  background-color: whitesmoke;
  bottom: 0;
  z-index: 1;
  width: 100%;
  margin-left: 1%;
  padding-bottom: 1%;
}
.footer-column {
  margin-left: 0.25rem !important;
}

ul.pagination-list {
  list-style-type: none;
}

span.column.is-12 {
  position: fixed;
  background-color: whitesmoke;
  right: 0rem;
  left: 0rem;
  width: 100%;
  padding: 0.5rem;
  bottom: 0;
  display: flex;
  justify-content: center;
}

@media (min-width: 360px) and (max-width: 1000px) {
  .toast {
    width: 95%;
  }
  .modal-card-foot > button {
    height: auto !important;
    font-size: 1.25rem !important;
    white-space: normal !important;
  }
}
@media (min-width: 360px) {
  .contents {
    padding-top: 11%;
  }
  .step-thank-you {
    padding-top: 7%;
  }
}
@media screen and (min-width: 1366px) {
  .wrapper {
    width: 1366px;
    margin: 0 auto;
    border: 1px solid whitesmoke;
  }
}
@media screen and (min-width: 1024px) {
  .header-desktop {
    width: inherit !important;
  }
  .step-thank-you {
    padding-top: 0;
  }
  .contents {
    padding-top: 4%;
  }
  .bar {
    padding-right: 2.5%;
    padding-top: 1.3%;
  }
  .btn-next {
    padding-top: 0.5%;
    padding-right: 3%;
  }
}
@media screen and (min-width: 1408px) {
  .bar {
    padding-right: 2.5%;
    padding-top: 0.7%;
  }
  .btn-next {
    padding-top: 0.5%;
    padding-right: 2.2%;
  }
}
.navbar.is-spaced {
  padding-right: 2.5%;
  padding-top: 0% !important;
}
.image-background {
  width: 100%;
  height: auto;
  text-align: center;
  color: white;
}
html,
body,
.height {
  height: 100% !important;
  width: 100% !important;
}
.container {
  height: 101.8vh;
}
.btn-take-photo {
  padding-top: 5%;
}
.steps {
  display: none !important;
}

body {
  display: flex;
  justify-content: center;
}
.photo {
  width: 360px;
  padding: 1%;
  border: 1px solid black;
}
.section {
  padding-top: 0.5rem !important;
}
@media screen and (min-width: 1216px) {
  .progress-wrapper {
    /* padding-top: 1%; */
    width: 23%;
  }
}
@media screen and (min-width: 1408px) {
  .progress-wrapper {
    /* padding-top: 1%; */
    width: 25%;
  }
  .progress {
    height: 23px !important;
  }
  .progress-wrapper .progress-value {
    top: 17% !important;
    font-size: 15px !important;
  }
}
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 500px;

  .camera-button {
    margin-bottom: 2rem;
  }

  .camera-box {
    .camera-shutter {
      opacity: 0;
      width: 450px;
      height: 337.5px;
      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }
  }

  .camera-shoot {
    margin: 1rem 0;

    button {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      img {
        height: 35px;
        object-fit: cover;
      }
    }
  }
  .question {
    margin-bottom: 20px;
  }
  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -1.2rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
}
.button.button.is-primary {
  background-color: #00a3a3;
}
ul {
  list-style: disc inside;
}
ol {
  list-style: inside decimal;
}
div.modal-card.animation-content {
  max-width: 860px;
  width: auto;
}

video::-webkit-media-controls {
  display: none;
}

/* Could Use thise as well for Individual Controls */
video::-webkit-media-controls-play-button {
}

video::-webkit-media-controls-volume-slider {
}

video::-webkit-media-controls-mute-button {
}

video::-webkit-media-controls-timeline {
}

video::-webkit-media-controls-current-time-display {
}
</style>
